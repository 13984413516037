.react-datepicker__month-select,
.react-datepicker__year-select {
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #9fa1a3;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
}

.react-datepicker__day--selected {
    background-color: #ff9800 !important;
}

.react-datepicker__day--today {
    background-color: #ff9800 !important;
}

.react-datepicker__day {
    width: 2rem;
}

.react-datepicker__navigation--next,
{
    border-left-color: #000000 !important;
}

.react-datepicker__navigation--previous {
    border-right-color: #000000 !important;
}