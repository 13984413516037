.multiselect {
    width: 100%;
    position: relative;
    font-family: 'Nunito' !important;
    box-sizing: border-box;
}

.multiselect-list {
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 0;
    left: 0;
    box-shadow: rgba(0, 0, 0,0.15) 0px 5px 20px;
    // border-radius: 3px;
    background: #fff;
    border: 1px solid #607d8b;
    transition: opacity .25s cubic-bezier(0,1,.4,1),transform .25s cubic-bezier(.18,1.25,.4,1);
   // box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.form-control-sm .multiselect-list {
    top: 34px;
}

.multiselect-option {
    list-style: none;
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid #607d8b;
}

.multiselect-options-list {
    padding-left: 0;
}

.multiselect-option:hover {
    background: #f9b756;
    color: #fff;
}

.focused {
    border-color: #f9b756;
}

.multiselect-input{
    cursor: pointer;
}

.multiselect-input:after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.multiselect-input::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 15px;
    width: 11px;
    height: 11px;
}