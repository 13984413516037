@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
@import './plugins/adminlte.scss';
@import './plugins/loading.scss';
@import './plugins/react-select.scss';
@import './plugins/my-select.scss';
@import './plugins/datepicker.scss';
@import './plugins/survey-modern.scss';
@import './plugins/survey-creator.scss';
@import './plugins/survey-analytics.scss';
@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

.jc {
  justify-content: center !important;
}

.ac {
  align-items: center !important;
}

.nav-link {
  display: flex !important;
  align-content: center !important;
  // justify-content: center !important;
}

.text-orange {
  color: #ff9800;
}

.text-old {
  color: #607d8b;
}


.nav-treeview svg {
  font-size: 0.8em;
}

.nav-treeview .nav-link {
  font-size: 0.9em;
  padding-left: 1.5rem;
}

.nav-sidebar .nav-treeview>.nav-item svg,
.nav-user-icon {
  width: 1.2rem;
  height: 1.5em;
  margin-right: .5rem;
  // color: #b5b5b5;
}

.nav-sidebar>.nav-item svg,
.nav-user-icon {
  width: 1.2rem;
  height: 1.5em;
  margin-right: .5rem;
  color: #ff9800;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #777777;
}

.nav-treeview>.nav-item>.nav-link.active {
  background-color: #adadad;
}

.wrapper .content-wrapper {
  min-height: calc(100vh - 58px);
}

.btn-primary {
  color: #FFFFFF;
  background-color: #FF9800;
  border-color: #FF9800;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #FFFFFF;
  background-color: #D17F04;
  border-color: #FF9800;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #FF9800;
  border-color: #FF9800;
}

.btn-primary .badge {
  color: #FF9800;
  background-color: #FFFFFF;
}

.btn-outline-primary {
  color: #FF9800;
  border-color: #FF9800;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #D17F04;
  border-color: #D17F04;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ffc518;
  background-color: transparent;
}

.close-circle {
  position: absolute;
  right: 1rem;
  border-radius: 50%;
  border: none;
  padding: .2rem;
  background: #D17F04;

}

.close-circle svg {
  color: #FFFFFF !important;
}

.brand-link .brand-image {
  margin-left: .5rem;
}

@media (min-width: 992px) {

  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar::before {
    width: 4.1rem;
  }
}

.sidebar-mini.sidebar-collapse {

  .dematick-logo-text {
    display: none;
  }

  .dematick-logo-text,
  .text-societe,
  .text-role {
    display: none;
  }

  .logo-admin {
    margin-left: 0.4rem;
  }
}


.sidebar-mini.sidebar-collapse .main-sidebar:hover {

  .text-societe,
  .text-role {
    display: block;
  }

  .logo-admin {
    margin-left: 0rem !important;
  }

  .dematick-logo-text {
    display: block;
  }
}

.filter-header,
.filter-collapse {
  background: #e2e2e2;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control:focus {
  border-color: #f9b756;
}

.popover.popover-filter {
  border: none;
  min-width: 350px !important;
  box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}


.popover.popover-columns {
  border: none;
  min-width: 400px !important;
  box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.pointer {
  cursor: pointer;
}

@media (min-width: 992px) {

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 4.1rem !important;
  }
}

.pointer,
.c-pointer {
  cursor: pointer;
}

.dematick-orange-grey {
  height: 70px;

  .top {
    fill: #ff9800;
  }

  .left {
    fill: #ff9800;
  }

  .right {
    fill: #ff9800;
  }

  .dematick-logo-text {
    fill: #607d8b;
  }
}

.dematick-orange-white {
  height: 40px;

  .top {
    fill: #ff9800;
  }

  .left {
    fill: #ff9800;
  }

  .right {
    fill: #ff9800;
  }

  .dematick-logo-text {
    fill: #ffffff;
  }
}

.nav-sidebar .menu-open>.nav-link svg.right {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.nav-sidebar .nav-link>.right:nth-child(2),
.nav-sidebar .nav-link>p>.right:nth-child(2) {
  right: 0.5rem;
  top: 1.1rem;
  font-size: 0.6em;
}


#progressbar {
  padding: 0;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 20%;
  float: left;
  position: relative;
  text-align: center;
}

#progressbar .progress-item-circle {
  width: 20px;
  height: 20px;
  line-height: 35px;
  display: block;
  font-size: 15px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 5px auto;
  padding: 2px;
  z-index: 2;
}

#progressbar .active .progress-item-circle {
  background: #fd7e13;
}

#progressbar .passed .progress-item-circle {
  background: #ffa75c;
}

#progressbar li:not(:last-child):after {
  content: '';
  width: 100%;
  height: 3px;
  background: lightgray;
  position: absolute;
  left: 50%;
  top: 9px;
  z-index: -1;
}

// #progressbar li.active:after {
//     background: $fid-back;
// }

#progressbar li.passed:after {
  background: #ffa75c;
}

.modal-xxxl {
  min-width: 95% !important;
}

.card-modele {
  transition: all .1s ease-in-out;
  cursor: pointer;
}

.card-modele.selected {
  border-width: 5px;
  border-color: #ffa75c;
}

.check-modele {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-weight: bold;
  color: #ffa75c;
  opacity: 0;
}

.visible {
  opacity: 1 !important;
}



.mobile-content {
  width: 370px;
  position: relative;
  transition: 0.3s width ease-in-out // width: auto;
}

.mobile-preview-samsung:before {
  width: 70px;
  position: absolute;
  height: 5px;
  margin-left: -50px;
  border: 1px solid #607d8b;
  border-radius: 5px;
  top: 6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview-samsung:after {
  position: absolute;
  width: 6px;
  height: 6px;
  margin-left: -25px;
  border: 1px solid #607d8b;
  border-radius: 100%;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview-samsung {
  width: 100%;
  height: 600px;
  padding: 12px 10px 12px;
  border-radius: 36px;
  margin: 0 auto;
  border: 2px solid #607d8b;
}

.mobile-preview-samsung .mobile-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 23px;
  border: 1px solid #607d8b;
}


.mobile-preview-samsung .mobile-wrapper iframe {
  height: 100%;
  width: 99.85%;
  border: 0px;
  border-radius: 23px;
  overflow: hidden;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.btn-edit-modele {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 19px;
}

.no-preview {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: auto
}

.mobile-preview {
  width: 100%;
  height: 570px;
  padding: 40px 20px 70px;
  border-radius: 20px;
  margin: 0 auto;
  border: 2px solid #607d8b;
}

.mobile-preview:before {
  width: 70px;
  position: absolute;
  height: 5px;
  margin-left: -50px;
  border: 1px solid #607d8b;
  border-radius: 5px;
  top: 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview:after {
  position: absolute;
  width: 40px;
  height: 40px;
  margin-left: -25px;
  border: 1px solid #607d8b;
  border-radius: 100%;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview .mobile-wrapper iframe {
  height: 100%;
  width: 99.85%;
  border: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.mobile-preview .mobile-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid #607d8b;
}

.desktop-header {
  height: 25px;
  position: relative;
  border-width: 2px 2px 0px 2px;
  border-color: #607d8b;
  border-style: solid;
}

.desktop-header:before {
  display: block;
  position: absolute;
  content: '';
  top: 0.65em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #fb5, 3em 0 0 2px #9b3;
}

.desktop-wrapper {
  border-color: #607d8b;
  border-width: 1px 2px 2px 2px;
  border-style: solid;
  min-height: 60vh;
}

.desktop-wrapper iframe {
  height: 100%;
  width: 100%;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.ticket {
  display: flex;
  justify-content: center;


}

.ticket img {
  max-width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, .35) 0px 5px 15px;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #ffffff;
  border-color: #ffa75c;
  background-color: #ffa75c;
  box-shadow: none;
}

.card-shop {
  border: 1px solid #607d8b;
  border-radius: 5px;
  transition: all .1s;
  cursor: pointer;
  min-height: 300px;
  transition: transform .4s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 20px;
}

.card-shop:hover {

  transform: scale(1.001);
}

.card-shop.selected {
  transform: scale(1.001);
  border: 2px solid #ffa75c;
  transform: scale(1.001);
}


.card-shop.selected .check-icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.avatar-first-letter {
  border-radius: 50%;
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  align-items: center;
  // border: 1px solid;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, .24), 0 8px 10px -5px rgba(0, 0, 0, .2);
  display: flex;
  margin: auto;
  color: #ffffff;
  font-size: 1.3rem;
  justify-content: center;
  background: #fd7e13;
}

.modele-image {
  border-radius: 4px;
  max-height: 70px;
}

.hover {
  border-radius: 4px;
  display: none;
  position: absolute;
  transition: all .3s;
}

.modele-card {
  transition: all .4s;
  border: 1px solid #abb3bb;
}

.modele-card:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 20px;
  transform: scale(1.03);
}

.modele-card:hover .hover {
  display: flex;
  inset: 0px;
  background: rgba(255, 255, 255, 0.8);
}

.card-choice {
  transition: all .1s ease-in-out;
  cursor: pointer;
}

.card-choice.selected {
  transform: scale(1.001);
  border: 2px solid #ffa75c;
  background:#ffa75c;
  color:#ffffff;
  transform: scale(1.001);
}

.card-choice.not-selected {
  // transform: scale(1.001);
  border: 2px solid #ffa75c;
  // transform: scale(1.001);
}


.check-choice {
  // position: absolute;
  // left: 0.5rem;
  // top: 0.5rem;
  font-weight: bold;
  color: #ffa75c;
  opacity: 0;
}

.selected .check-choice {
  // position: absolute;
  // left: 0.5rem;
  // top: 0.5rem;
  font-weight: bold;
  color: #ffffff;
  opacity: 0;
}

div.is-invalid{
  border-radius: 5px;
  border : 1px solid #dc3545 !important;
}

.ant-modal-wrap{
  z-index: 1000000000000;
}

.shop-logo .ant-upload.ant-upload-select-picture-card {
  width: 250px;
  height: 100%;
}

.ticket-json {
  width: 400px;
  background: #ffffff;
  padding: 0px 20px 50px 20px;
  border-radius: 5px;
}

.ticket-json table {
  width: 100%;
}
